:root {
  --font-default: Open Sans, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  --font-primary: Roboto, sans-serif;
  --font-secondary: Work Sans, sans-serif;
  --color-default: #364d59;
  --color-primary: #feb900;
  --color-secondary: #52565e;
  scroll-behavior: smooth;
}

body {
  font-family: var(--font-default);
  color: var(--color-default);
  overflow-x: hidden;
}

a {
  color: var(--color-primary);
  text-decoration: none;
}

a:hover {
  color: #ffc732;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-primary);
}

table {
  caption-side: top;
}

table caption {
  color: #000;
  border-bottom: thin solid #ffc732;
  padding: .5rem;
  font-size: 1.5rem;
}

table th, table td {
  text-align: left;
}

section {
  padding: 80px 0;
  overflow: hidden;
}

.section-bg {
  background-color: #f5f6f7;
}

.section-header {
  text-align: center;
  padding-bottom: 70px;
}

.section-header h2 {
  color: #2e3135;
  font-size: 32px;
  font-weight: 700;
  position: relative;
}

.section-header h2:before, .section-header h2:after {
  content: "";
  width: 50px;
  height: 2px;
  background: var(--color-primary);
  display: inline-block;
}

.section-header h2:before {
  margin: 0 15px 10px 0;
}

.section-header h2:after {
  margin: 0 0 10px 15px;
}

.section-header p {
  margin: 0 auto;
}

@media (min-width: 1199px) {
  .section-header p {
    max-width: 60%;
  }
}

.breadcrumbs {
  min-height: 60vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 140px 0 60px;
  position: relative;
}

.breadcrumbs:before {
  content: "";
  background-color: #0009;
  position: absolute;
  inset: 0;
}

.breadcrumbs h2 {
  color: #fff;
  font-size: 56px;
  font-weight: 500;
  font-family: var(--font-secondary);
}

.breadcrumbs ol {
  color: var(--color-primary);
  flex-wrap: wrap;
  margin: 0;
  padding: 0 0 10px;
  font-size: 16px;
  font-weight: 600;
  list-style: none;
  display: flex;
}

.breadcrumbs ol a {
  color: #fffc;
  transition: all .3s;
}

.breadcrumbs ol a:hover {
  text-decoration: underline;
}

.breadcrumbs ol li + li {
  padding-left: 10px;
}

.breadcrumbs ol li + li:before {
  color: #fff;
  content: "/";
  padding-right: 10px;
  display: inline-block;
}

.scroll-top {
  visibility: hidden;
  opacity: 0;
  z-index: 99999;
  background: var(--color-primary);
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all .4s;
  position: fixed;
  bottom: 15px;
  right: 15px;
}

.scroll-top i {
  color: var(--color-secondary);
  font-size: 24px;
  line-height: 0;
}

.scroll-top:hover {
  color: #fff;
  background: #ffc732;
}

.scroll-top.active {
  visibility: visible;
  opacity: 1;
}

#preloader {
  z-index: 999999;
  background: #fff;
  transition: all .6s ease-out;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

#preloader:before {
  content: "";
  border: 6px solid #fff;
  border-color: var(--color-primary) transparent var(--color-primary) transparent;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  animation: animate-preloader 1.5s linear infinite;
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

.header {
  z-index: 997;
  padding: 30px 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.header .logo img {
  max-height: 40px;
  margin-right: 6px;
}

.header .logo h1 {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  font-family: var(--font-primary);
  margin-bottom: 0;
}

.header .logo h1 span {
  color: var(--color-primary);
}

@media (min-width: 1280px) {
  .navbar {
    padding: 0;
  }

  .navbar ul {
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
  }

  .navbar li {
    position: relative;
  }

  .navbar > ul > li {
    white-space: nowrap;
    padding: 10px 0 10px 28px;
  }

  .navbar a, .navbar a:focus {
    font-family: var(--font-primary);
    color: #fff9;
    text-transform: uppercase;
    white-space: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 3px;
    font-size: 14px;
    font-weight: 500;
    transition: all .3s;
    display: flex;
    position: relative;
  }

  .navbar a i, .navbar a:focus i {
    margin-left: 5px;
    font-size: 12px;
    line-height: 0;
  }

  .navbar > ul > li > a:before {
    content: "";
    width: 100%;
    height: 2px;
    background-color: var(--color-primary);
    visibility: hidden;
    width: 0;
    transition: all .3s ease-in-out;
    position: absolute;
    bottom: -6px;
    left: 0;
  }

  .navbar a:hover:before, .navbar li:hover > a:before, .navbar .active:before {
    visibility: visible;
    width: 100%;
  }

  .navbar a:hover, .navbar .active, .navbar .active:focus, .navbar li:hover > a {
    color: #fff;
  }

  .navbar .dropdown ul {
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    margin: 0;
    padding: 10px 0;
    transition: all .3s;
    display: block;
    position: absolute;
    top: calc(100% + 30px);
    left: 28px;
    box-shadow: 0 0 30px #7f89a140;
  }

  .navbar .dropdown ul li {
    min-width: 200px;
  }

  .navbar .dropdown ul a {
    text-transform: none;
    color: var(--color-default);
    padding: 10px 20px;
    font-size: 15px;
    font-weight: 400;
  }

  .navbar .dropdown ul a i {
    font-size: 12px;
  }

  .navbar .dropdown ul a:hover, .navbar .dropdown ul .active:hover, .navbar .dropdown ul li:hover > a {
    color: var(--color-primary);
  }

  .navbar .dropdown:hover > ul {
    opacity: 1;
    visibility: visible;
    top: 100%;
  }

  .navbar .dropdown .dropdown ul {
    visibility: hidden;
    top: 0;
    left: calc(100% - 30px);
  }

  .navbar .dropdown .dropdown:hover > ul {
    opacity: 1;
    visibility: visible;
    top: 0;
    left: 100%;
  }
}

@media (min-width: 1280px) and (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

@media (min-width: 1280px) {
  .navbar .mobile-nav-show, .navbar .mobile-nav-hide {
    display: none;
  }
}

@media (max-width: 1279px) {
  .navbar {
    width: 100%;
    max-width: 400px;
    z-index: 9997;
    transition: all .3s;
    position: fixed;
    top: 0;
    bottom: 0;
    right: -100%;
  }

  .navbar ul {
    z-index: 9998;
    background: #000c;
    margin: 0;
    padding: 50px 0 10px;
    transition: all .3s;
    position: absolute;
    inset: 0;
    overflow-y: auto;
  }

  .navbar a, .navbar a:focus {
    font-family: var(--font-primary);
    color: #ffffffb3;
    white-space: nowrap;
    text-transform: uppercase;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    font-size: 15px;
    font-weight: 500;
    transition: all .3s;
    display: flex;
  }

  .navbar a i, .navbar a:focus i {
    margin-left: 5px;
    font-size: 12px;
    line-height: 0;
  }

  .navbar a:hover, .navbar .active, .navbar .active:focus, .navbar li:hover > a {
    color: #fff;
  }

  .navbar .dropdown ul, .navbar .dropdown .dropdown ul {
    border: 1px solid #222428;
    margin: 10px 20px;
    padding: 10px 0;
    transition: all .5s ease-in-out;
    display: none;
    position: static;
  }

  .navbar .dropdown > .dropdown-active, .navbar .dropdown .dropdown > .dropdown-active {
    display: block;
  }

  .mobile-nav-show {
    color: #fff;
    cursor: pointer;
    z-index: 9999;
    padding-right: 10px;
    font-size: 28px;
    line-height: 0;
    transition: all .5s;
    position: relative;
  }

  .mobile-nav-hide {
    color: #fff;
    cursor: pointer;
    z-index: 9999;
    font-size: 32px;
    line-height: 0;
    transition: all .5s;
    position: fixed;
    top: 20px;
    right: 20px;
  }

  .mobile-nav-active {
    overflow: hidden;
  }

  .mobile-nav-active .navbar {
    right: 0;
  }

  .mobile-nav-active .navbar:before {
    content: "";
    z-index: 9996;
    background: #0009;
    position: fixed;
    inset: 0;
  }
}

.get-started .content {
  padding: 30px 0;
}

.get-started .content h3 {
  color: var(--color-secondary);
  margin-bottom: 25px;
  padding-bottom: 25px;
  font-size: 36px;
  font-weight: 600;
  position: relative;
}

.get-started .content h3:after {
  content: "";
  width: 60px;
  height: 4px;
  background: var(--color-primary);
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.get-started .content p {
  font-size: 14px;
}

.get-started .php-email-form {
  height: 100%;
  background: #fff;
  padding: 30px;
}

@media (max-width: 575px) {
  .get-started .php-email-form {
    padding: 20px;
  }
}

.get-started .php-email-form h3 {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 700;
}

.get-started .php-email-form p {
  margin-bottom: 20px;
  font-size: 14px;
}

.get-started .php-email-form .error-message {
  color: #fff;
  text-align: left;
  background: #df1529;
  margin-bottom: 24px;
  padding: 15px;
  font-weight: 600;
  display: none;
}

.get-started .php-email-form .sent-message {
  color: #fff;
  text-align: center;
  background: #059652;
  margin-bottom: 24px;
  padding: 15px;
  font-weight: 600;
  display: none;
}

.get-started .php-email-form .loading {
  text-align: center;
  background: #fff;
  margin-bottom: 24px;
  padding: 15px;
  display: none;
}

.get-started .php-email-form .loading:before {
  content: "";
  width: 24px;
  height: 24px;
  border: 3px solid #059652;
  border-top-color: #fff;
  border-radius: 50%;
  margin: 0 10px -6px 0;
  animation: animate-loading 1s linear infinite;
  display: inline-block;
}

.get-started .php-email-form input, .get-started .php-email-form textarea {
  box-shadow: none;
  border-radius: 0;
  font-size: 14px;
}

.get-started .php-email-form input:focus, .get-started .php-email-form textarea:focus {
  border-color: var(--color-primary);
}

.get-started .php-email-form input, .get-started .php-email-form textarea {
  padding: 12px 15px;
}

.get-started .php-email-form button[type="submit"] {
  background: var(--color-primary);
  color: #fff;
  border: 0;
  border-radius: 4px;
  padding: 10px 30px;
  transition: all .4s;
}

.get-started .php-email-form button[type="submit"]:hover {
  background: #feb900cc;
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.constructions .card-item {
  background: #fff;
  border: 1px solid #52565e33;
  border-radius: 0;
  position: relative;
}

.constructions .card-item .card-bg {
  min-height: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.constructions .card-item .card-body {
  padding: 30px;
}

.constructions .card-item h4 {
  color: var(--color-secondary);
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 700;
}

.constructions .card-item p {
  color: var(--color-secondary);
  margin: 0;
}

.services .service-item {
  height: 100%;
  background: #fff;
  padding: 40px;
}

.services .service-item .icon {
  width: 48px;
  height: 48px;
  margin-bottom: 50px;
  position: relative;
}

.services .service-item .icon i {
  color: var(--color-secondary);
  z-index: 2;
  font-size: 40px;
  line-height: 1.8;
  transition: all .3s ease-in-out;
  position: relative;
}

.services .service-item .icon:before {
  content: "";
  height: 100%;
  width: 100%;
  z-index: 1;
  background: #f0f1f2;
  border-radius: 50px;
  transition: all .3s;
  position: absolute;
  top: 10px;
  right: -15px;
}

.services .service-item h3 {
  color: var(--color-default);
  border-bottom: 4px solid #ebebed;
  margin: 0 0 20px;
  padding-bottom: 8px;
  font-size: 22px;
  font-weight: 700;
  transition: all .3s;
  display: inline-block;
  position: relative;
}

.services .service-item p {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 24px;
}

.services .service-item .readmore {
  color: var(--color-primary);
  margin-top: 15px;
  display: inline-block;
}

.services .service-item:hover .icon:before {
  background: var(--color-primary);
}

.services .service-item:hover h3 {
  border-color: var(--color-primary);
}

.features .nav-tabs {
  border: 0;
}

.features .nav-link {
  color: var(--color-secondary);
  cursor: pointer;
  height: 100%;
  border: 0;
  border-bottom: 4px solid #e2e4e6;
  border-radius: 0;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  transition: all .3s;
  display: flex;
}

.features .nav-link i {
  padding-right: 15px;
  font-size: 48px;
}

.features .nav-link h4 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

@media (max-width: 575px) {
  .features .nav-link h4 {
    font-size: 16px;
  }
}

.features .nav-link:hover {
  color: var(--color-primary);
}

.features .nav-link.active {
  color: var(--color-primary);
  border-color: var(--color-primary);
  background-color: #0000;
}

.features .tab-content {
  margin-top: 30px;
}

.features .tab-pane h3 {
  margin-bottom: 20px;
  padding-bottom: 20px;
  font-size: 32px;
  font-weight: 700;
  position: relative;
}

.features .tab-pane h3:after {
  content: "";
  width: 60px;
  height: 3px;
  background: var(--color-primary);
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.features .tab-pane ul {
  padding: 0;
  list-style: none;
}

.features .tab-pane ul li {
  padding-top: 10px;
}

.features .tab-pane ul i {
  color: var(--color-primary);
  padding-right: 4px;
  font-size: 20px;
}

.features .tab-pane p:last-child {
  margin-bottom: 0;
}

.projects .portfolio-flters {
  text-align: center;
  margin: 0 auto 20px;
  padding: 0;
  list-style: none;
}

.projects .portfolio-flters li {
  cursor: pointer;
  margin: 0 10px 5px;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
  transition: all .3s ease-in-out;
  display: inline-block;
}

.projects .portfolio-flters li:hover, .projects .portfolio-flters li.filter-active {
  color: var(--color-primary);
}

.projects .portfolio-flters li:first-child {
  margin-left: 0;
}

.projects .portfolio-flters li:last-child {
  margin-right: 0;
}

@media (max-width: 575px) {
  .projects .portfolio-flters li {
    margin: 0 5px;
    font-size: 14px;
  }
}

.projects .portfolio-content {
  position: relative;
  overflow: hidden;
}

.projects .portfolio-content img {
  transition: all .3s;
}

.projects .portfolio-content .portfolio-info {
  opacity: 0;
  z-index: 3;
  background: #0009;
  padding: 15px;
  transition: all .3s ease-in-out;
  position: absolute;
  inset: 0;
}

.projects .portfolio-content .portfolio-info h4 {
  color: #fff;
  background-color: var(--color-primary);
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 400;
  display: inline-block;
}

.projects .portfolio-content .portfolio-info p {
  text-align: center;
  color: #fffc;
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
}

.projects .portfolio-content .portfolio-info .preview-link, .projects .portfolio-content .portfolio-info .details-link {
  color: #fff;
  font-size: 26px;
  line-height: 1.2;
  transition: all .3s;
  position: absolute;
  top: calc(50% - 14px);
  left: calc(50% - 40px);
}

.projects .portfolio-content .portfolio-info .preview-link:hover, .projects .portfolio-content .portfolio-info .details-link:hover {
  color: var(--color-primary);
}

.projects .portfolio-content .portfolio-info .details-link {
  font-size: 34px;
  line-height: 0;
  left: 50%;
}

.projects .portfolio-content:hover .portfolio-info {
  opacity: 1;
}

.projects .portfolio-content:hover img {
  transform: scale(1.1);
}

.testimonials .testimonial-wrap {
  padding-left: 50px;
}

.testimonials .testimonials-carousel, .testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item {
  box-sizing: content-box;
  min-height: 200px;
  background: #fff;
  margin: 0 15px 30px 0;
  padding: 30px 30px 30px 60px;
  position: relative;
  box-shadow: 0 2px 20px #52565e1a;
}

.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border: 6px solid #fff;
  border-radius: 10px;
  position: absolute;
  left: -45px;
}

.testimonials .testimonial-item h3 {
  color: #000;
  margin: 10px 0 5px;
  font-size: 18px;
  font-weight: bold;
}

.testimonials .testimonial-item h4 {
  color: #999;
  margin: 0;
  font-size: 14px;
}

.testimonials .testimonial-item .stars {
  margin: 10px 0;
}

.testimonials .testimonial-item .stars i {
  color: #ffc107;
  margin: 0 1px;
}

.testimonials .testimonial-item .quote-icon-left, .testimonials .testimonial-item .quote-icon-right {
  color: #ffd565;
  font-size: 26px;
  line-height: 0;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  position: relative;
  left: -5px;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  position: relative;
  top: 10px;
  right: -5px;
  transform: scale(-1);
}

.testimonials .testimonial-item p {
  margin: 15px auto;
  font-style: italic;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  opacity: 1;
  background-color: #d5d7da;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--color-primary);
}

@media (max-width: 767px) {
  .testimonials .testimonial-wrap {
    padding-left: 0;
  }

  .testimonials .testimonials-carousel, .testimonials .testimonials-slider {
    overflow: hidden;
  }

  .testimonials .testimonial-item {
    margin: 15px;
    padding: 30px;
  }

  .testimonials .testimonial-item .testimonial-img {
    position: static;
    left: auto;
  }
}

.alt-services .img-bg {
  min-height: 400px;
  background-position: center;
  background-size: cover;
}

.alt-services h3 {
  color: var(--color-secondary);
  margin-bottom: 20px;
  padding-bottom: 20px;
  font-size: 28px;
  font-weight: 700;
  position: relative;
}

.alt-services h3:after {
  content: "";
  width: 50px;
  height: 3px;
  background: var(--color-primary);
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.alt-services .icon-box {
  margin-top: 50px;
}

.alt-services .icon-box i {
  color: var(--color-primary);
  width: 56px;
  height: 56px;
  background-color: #fff;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  margin-right: 25px;
  font-size: 28px;
  line-height: 0;
  transition: all .3s;
  display: flex;
  box-shadow: 0 2px 30px #00000014;
}

.alt-services .icon-box:hover i {
  background-color: var(--color-primary);
  color: #fff;
}

.alt-services .icon-box h4 {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 700;
}

.alt-services .icon-box h4 a {
  color: #000;
  transition: all .3s;
}

.alt-services .icon-box h4 a:hover {
  color: var(--color-primary);
}

.alt-services .icon-box p {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 24px;
}

.about h2 {
  font-size: 48px;
  font-weight: 700;
  font-family: var(--font-secondary);
  margin: 30px 0;
}

@media (min-width: 991px) {
  .about h2 {
    max-width: 65%;
    margin: 0 0 80px;
  }
}

.about .our-story {
  background-color: #f5f6f7;
  padding: 40px;
}

@media (min-width: 991px) {
  .about .our-story {
    padding-right: 35%;
  }
}

.about .our-story h4 {
  text-transform: uppercase;
  color: #838893;
  font-size: 18px;
}

.about .our-story h3 {
  color: var(--color-secondary);
  margin-bottom: 20px;
  font-size: 36px;
  font-weight: 700;
}

.about .our-story p:last-child {
  margin-bottom: 0;
}

.about ul {
  padding: 0;
  font-size: 15px;
  list-style: none;
}

.about ul li {
  align-items: center;
  padding: 5px 0;
  display: flex;
}

.about ul i {
  color: var(--color-primary);
  margin-right: 4px;
  font-size: 20px;
}

.about .watch-video i {
  color: var(--color-primary);
  font-size: 32px;
  transition: all .3s;
}

.about .watch-video a {
  color: var(--color-secondary);
  margin-left: 8px;
  font-weight: 600;
  transition: all .3s;
}

.about .watch-video:hover a {
  color: var(--color-primary);
}

.about .about-img {
  min-height: 600px;
  background-position: center;
  background-size: cover;
}

@media (min-width: 992px) {
  .about .about-img {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.stats-counter .stats-item {
  background: #fff;
  padding: 30px;
  box-shadow: 0 0 30px #52565e0d;
}

.stats-counter .stats-item i {
  color: var(--color-primary);
  margin-right: 20px;
  font-size: 42px;
  line-height: 0;
}

.stats-counter .stats-item span {
  color: var(--color-secondary);
  font-size: 36px;
  font-weight: 600;
  display: block;
}

.stats-counter .stats-item p {
  font-family: var(--font-primary);
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.team .member {
  position: relative;
}

.team .member .member-img {
  border-radius: 50%;
  margin: 0 80px;
  position: relative;
  overflow: hidden;
}

@media (max-width: 1024px) {
  .team .member .member-img {
    margin: 0 60px;
  }
}

.team .member .member-img img {
  z-index: 1;
  position: relative;
}

.team .member .member-img .social {
  z-index: 2;
  visibility: hidden;
  opacity: 0;
  background-color: #0009;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  transition: all .3s;
  display: flex;
  position: absolute;
  inset: 0;
}

.team .member .member-img .social a {
  color: #fff;
  margin: 0 8px;
  font-size: 20px;
  transition: all .3s;
}

.team .member .member-img .social a:hover {
  color: var(--color-primary);
}

.team .member .member-info {
  margin-top: 30px;
}

.team .member .member-info h4 {
  color: var(--color-secondary);
  margin-bottom: 6px;
  font-size: 18px;
  font-weight: 700;
}

.team .member .member-info span {
  color: #838893;
  margin-bottom: 10px;
  font-size: 15px;
  font-style: italic;
  display: block;
}

.team .member .member-info p {
  margin-bottom: 0;
  font-size: 14px;
}

.team .member:hover .member-img .social {
  visibility: visible;
  opacity: 1;
  padding-bottom: 0;
}

.services-cards h3 {
  color: var(--color-secondary);
  font-size: 20px;
  font-weight: 700;
}

.services-cards p {
  font-size: 15px;
}

.services-cards ul li {
  align-items: center;
  padding-top: 10px;
  font-size: 14px;
  display: flex;
}

.services-cards ul li i {
  color: var(--color-primary);
  margin-right: 6px;
  font-size: 16px;
}

.project-details .portfolio-details-slider img {
  width: 100%;
}

.project-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  opacity: 1;
  background-color: #ffffffb3;
}

.project-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--color-primary);
}

.project-details .swiper-button-prev, .project-details .swiper-button-next {
  width: 48px;
  height: 48px;
}

.project-details .swiper-button-prev:after, .project-details .swiper-button-next:after {
  color: #fffc;
  width: 48px;
  height: 48px;
  background-color: #0003;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  transition: all .3s;
  display: flex;
}

.project-details .swiper-button-prev:hover:after, .project-details .swiper-button-next:hover:after {
  background-color: #0009;
}

@media (max-width: 575px) {
  .project-details .swiper-button-prev, .project-details .swiper-button-next {
    display: none;
  }
}

.project-details .portfolio-info h3 {
  margin-bottom: 20px;
  padding-bottom: 20px;
  font-size: 22px;
  font-weight: 700;
  position: relative;
}

.project-details .portfolio-info h3:after {
  content: "";
  width: 50px;
  height: 3px;
  background: var(--color-primary);
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.project-details .portfolio-info ul {
  padding: 0;
  font-size: 15px;
  list-style: none;
}

.project-details .portfolio-info ul li {
  flex-direction: column;
  padding-bottom: 15px;
  display: flex;
}

.project-details .portfolio-info ul strong {
  text-transform: uppercase;
  color: #838893;
  font-size: 14px;
  font-weight: 400;
}

.project-details .portfolio-info .btn-visit {
  background: var(--color-primary);
  color: #fff;
  border-radius: 50px;
  padding: 8px 40px;
  transition: all .3s;
}

.project-details .portfolio-info .btn-visit:hover {
  background: #ffc019;
}

.project-details .portfolio-description h2 {
  color: var(--color-secondary);
  margin-bottom: 20px;
  font-size: 26px;
  font-weight: 700;
}

.project-details .portfolio-description p {
  padding: 0;
}

.project-details .portfolio-description .testimonial-item {
  height: 100%;
  background: #f5f6f7;
  margin-bottom: 50px;
  padding: 30px 30px 0;
  position: relative;
}

.project-details .portfolio-description .testimonial-item .testimonial-img {
  width: 90px;
  float: left;
  border: 6px solid #fff;
  border-radius: 50px;
  margin: 0 10px 0 0;
}

.project-details .portfolio-description .testimonial-item h3 {
  margin: 15px 0 5px;
  padding-top: 20px;
  font-size: 18px;
  font-weight: bold;
}

.project-details .portfolio-description .testimonial-item h4 {
  color: #6c757d;
  margin: 0;
  font-size: 14px;
}

.project-details .portfolio-description .testimonial-item .quote-icon-left, .project-details .portfolio-description .testimonial-item .quote-icon-right {
  color: #ffd565;
  font-size: 26px;
  line-height: 0;
}

.project-details .portfolio-description .testimonial-item .quote-icon-left {
  display: inline-block;
  position: relative;
  left: -5px;
}

.project-details .portfolio-description .testimonial-item .quote-icon-right {
  display: inline-block;
  position: relative;
  top: 10px;
  right: -5px;
  transform: scale(-1);
}

.project-details .portfolio-description .testimonial-item p {
  margin: 0 0 15px 0 0 0;
  padding: 0;
  font-style: italic;
}

.service-details .services-list {
  border: 1px solid #d5d7da;
  margin-bottom: 20px;
  padding: 10px 30px;
}

.service-details .services-list a {
  color: var(--color-secondary);
  border-left: 3px solid #d9e3e8;
  margin: 20px 0;
  padding: 8px 0 8px 15px;
  line-height: 1;
  transition: all .3s;
  display: block;
}

.service-details .services-list a.active {
  border-color: var(--color-primary);
  font-weight: 700;
}

.service-details .services-list a:hover {
  border-color: var(--color-primary);
}

.service-details .services-img {
  margin-bottom: 20px;
}

.service-details h3 {
  font-size: 28px;
  font-weight: 700;
}

.service-details h4 {
  font-size: 20px;
  font-weight: 700;
}

.service-details p {
  font-size: 15px;
}

.service-details ul {
  padding: 0;
  font-size: 15px;
  list-style: none;
}

.service-details ul li {
  align-items: center;
  padding: 5px 0;
  display: flex;
}

.service-details ul i {
  color: var(--color-primary);
  margin-right: 8px;
  font-size: 20px;
}

.contact .info-item {
  padding: 20px 0 30px;
  box-shadow: 0 0 25px #00000014;
}

.contact .info-item i {
  width: 56px;
  height: 56px;
  color: var(--color-primary);
  border: 2px dotted #ffd565;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  line-height: 0;
  display: flex;
}

.contact .info-item h3 {
  color: #6c757d;
  margin: 10px 0;
  font-size: 20px;
  font-weight: 700;
}

.contact .info-item p {
  margin-bottom: 0;
  padding: 0;
  font-size: 14px;
  line-height: 24px;
}

.contact .php-email-form {
  width: 100%;
  background: #fff;
  padding: 30px;
  box-shadow: 0 0 25px #00000014;
}

.contact .php-email-form .form-group {
  padding-bottom: 20px;
}

.contact .php-email-form .error-message {
  color: #fff;
  text-align: left;
  background: #df1529;
  padding: 15px;
  font-weight: 600;
  display: none;
}

.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  color: #fff;
  text-align: center;
  background: #059652;
  padding: 15px;
  font-weight: 600;
  display: none;
}

.contact .php-email-form .loading {
  text-align: center;
  background: #fff;
  padding: 15px;
  display: none;
}

.contact .php-email-form .loading:before {
  content: "";
  width: 24px;
  height: 24px;
  border: 3px solid #059652;
  border-top-color: #fff;
  border-radius: 50%;
  margin: 0 10px -6px 0;
  animation: animate-loading 1s linear infinite;
  display: inline-block;
}

.contact .php-email-form input, .contact .php-email-form textarea {
  box-shadow: none;
  border-radius: 0;
  font-size: 14px;
}

.contact .php-email-form input:focus, .contact .php-email-form textarea:focus {
  border-color: var(--color-primary);
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

.contact .php-email-form button[type="submit"] {
  background: var(--color-primary);
  color: #fff;
  border: 0;
  border-radius: 5px;
  padding: 10px 35px;
  transition: all .4s;
}

.contact .php-email-form button[type="submit"]:hover {
  background: #feb900cc;
}

.recent-blog-posts .post-item {
  transition: all .3s;
  box-shadow: 0 2px 20px #0000000f;
}

.recent-blog-posts .post-item .post-img img {
  transition: all .5s;
}

.recent-blog-posts .post-item .post-date {
  background-color: var(--color-primary);
  color: #fff;
  text-transform: uppercase;
  padding: 6px 12px;
  font-size: 13px;
  font-weight: 500;
  position: absolute;
  bottom: 0;
  right: 0;
}

.recent-blog-posts .post-item .post-content {
  padding: 30px;
}

.recent-blog-posts .post-item .post-title {
  color: var(--color-secondary);
  margin-bottom: 15px;
  font-size: 24px;
  font-weight: 700;
  transition: all .3s;
}

.recent-blog-posts .post-item .meta i {
  color: var(--color-primary);
  font-size: 16px;
}

.recent-blog-posts .post-item .meta span {
  color: #838893;
  font-size: 15px;
}

.recent-blog-posts .post-item hr {
  color: #888;
  margin: 20px 0;
}

.recent-blog-posts .post-item .readmore {
  color: #838893;
  align-items: center;
  font-weight: 600;
  line-height: 1;
  transition: all .3s;
  display: flex;
}

.recent-blog-posts .post-item .readmore i {
  margin-left: 6px;
  font-size: 16px;
  line-height: 0;
}

.recent-blog-posts .post-item:hover .post-title, .recent-blog-posts .post-item:hover .readmore {
  color: var(--color-primary);
}

.recent-blog-posts .post-item:hover .post-img img {
  transform: scale(1.1);
}

.hero {
  padding: 0;
  overflow-x: hidden;
}

.hero .carousel {
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 80px 0;
  position: relative;
}

.hero .carousel-item {
  z-index: 1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition-duration: .4s;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.hero .carousel-item:before {
  content: "";
  background-color: #000000b3;
  position: absolute;
  inset: 0;
}

.hero .info {
  z-index: 2;
  position: absolute;
  inset: 0;
}

@media (max-width: 768px) {
  .hero .info {
    padding: 0 50px;
  }
}

.hero .info h2 {
  color: #fff;
  margin-bottom: 30px;
  padding-bottom: 30px;
  font-size: 56px;
  font-weight: 700;
  position: relative;
}

.hero .info h2:after {
  content: "";
  width: 80px;
  height: 4px;
  background: var(--color-primary);
  margin: auto;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (max-width: 1199px) {
  .hero .hmobile {
    display: none;
  }

  .hero .info h2 {
    flex-direction: column;
    display: flex;
  }
}

@media (max-width: 768px) {
  .hero .info p {
    color: #fffc;
    font-size: 18px;
  }

  .hero .info .btn-get-started {
    font-family: var(--font-primary);
    letter-spacing: 1px;
    color: #fff;
    border: 2px solid var(--color-primary);
    border-radius: 50px;
    margin: 10px;
    padding: 12px 40px;
    font-size: 16px;
    font-weight: 500;
    transition: all .5s;
    display: inline-block;
  }

  .hero .info .btn-get-started:hover {
    background: var(--color-primary);
  }

  .hero .carousel-control-prev {
    justify-content: start;
  }

  @media (min-width: 640px) {
    .hero .carousel-control-prev {
      padding-left: 15px;
    }
  }

  .hero .carousel-control-next {
    justify-content: end;
  }
}

@media (min-width: 640px) {
  .hero .carousel-control-next {
    padding-right: 15px;
  }
}

.hero .carousel-control-next-icon, .hero .carousel-control-prev-icon {
  color: #fff9;
  width: 54px;
  height: 54px;
  background: #fff3;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  line-height: 0;
  display: flex;
}

.hero .carousel-control-prev, .hero .carousel-control-next {
  z-index: 3;
  transition: all .3s;
}

.hero .carousel-control-prev:focus, .hero .carousel-control-next:focus {
  opacity: .5;
}

.hero .carousel-control-prev:hover, .hero .carousel-control-next:hover {
  opacity: .9;
}

.blog .blog-pagination {
  color: #838893;
  margin-top: 30px;
}

.blog .blog-pagination ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.blog .blog-pagination li {
  margin: 0 5px;
  transition: all .3s;
}

.blog .blog-pagination li a {
  color: var(--color-secondary);
  justify-content: center;
  align-items: center;
  padding: 7px 16px;
  display: flex;
}

.blog .blog-pagination li.active, .blog .blog-pagination li:hover {
  background: var(--color-primary);
  color: #fff;
}

.blog .blog-pagination li.active a, .blog .blog-pagination li:hover a {
  color: var(--color-white);
}

.blog .posts-list .post-item {
  transition: all .3s;
  box-shadow: 0 2px 20px #0000000f;
}

.blog .posts-list .post-img img {
  transition: all .5s;
}

.blog .posts-list .post-content {
  padding: 30px;
}

.blog .posts-list .post-title {
  color: var(--color-secondary);
  margin-bottom: 15px;
  font-size: 24px;
  font-weight: 700;
  transition: all .3s;
}

.blog .posts-list .meta i {
  color: var(--color-primary);
  font-size: 16px;
}

.blog .posts-list .meta span {
  color: #838893;
  font-size: 15px;
}

.blog .posts-list p {
  margin-top: 20px;
}

.blog .posts-list hr {
  color: #888;
  margin-bottom: 20px;
}

.blog .posts-list .readmore {
  color: #838893;
  align-items: center;
  font-weight: 600;
  line-height: 2rem;
  transition: all .3s;
  display: flex;
}

.blog .posts-list .readmore i {
  margin-right: 6px;
  font-size: 16px;
  line-height: 0;
}

.blog .posts-list a:hover {
  color: var(--color-primary);
}

.blog .posts-list .post-item:hover .post-img img {
  transform: scale(1.1);
}

.blog .blog-details {
  padding: 30px;
  box-shadow: 0 4px 16px #0000001a;
}

.blog .blog-details .post-img {
  margin: -30px -30px 20px;
  overflow: hidden;
}

.blog .blog-details .title {
  color: var(--color-secondary);
  margin: 20px 0 0;
  padding: 0;
  font-size: 28px;
  font-weight: 700;
}

.blog .blog-details .content {
  margin-top: 20px;
}

.blog .blog-details .content h3 {
  margin-top: 30px;
  font-size: 22px;
  font-weight: bold;
}

.blog .blog-details .content blockquote {
  text-align: center;
  background-color: #52565e0f;
  margin: 20px 0;
  padding: 60px;
  position: relative;
  overflow: hidden;
}

.blog .blog-details .content blockquote p {
  color: var(--color-default);
  margin-bottom: 0;
  font-size: 22px;
  font-style: italic;
  font-weight: 500;
  line-height: 1.6;
}

.blog .blog-details .meta-top {
  color: #6c757d;
  margin-top: 20px;
}

.blog .blog-details .meta-top ul {
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.blog .blog-details .meta-top ul li + li {
  padding-left: 20px;
}

.blog .blog-details .meta-top i {
  color: var(--color-primary);
  margin-right: 8px;
  font-size: 16px;
  line-height: 0;
}

.blog .blog-details .meta-top a {
  color: #6c757d;
  font-size: 14px;
  line-height: 1;
  transition: all .3s;
  display: inline-block;
}

.blog .blog-details .meta-top a:hover {
  color: var(--color-primary);
}

.blog .blog-details .meta-bottom {
  border-top: 1px solid #52565e26;
  padding-top: 10px;
}

.blog .blog-details .meta-bottom i {
  color: #838893;
  display: inline;
}

.blog .blog-details .meta-bottom a {
  color: #52565ecc;
  transition: all .3s;
}

.blog .blog-details .meta-bottom a:hover {
  color: var(--color-primary);
}

.blog .blog-details .meta-bottom .cats {
  padding: 0 20px 0 0;
  font-size: 14px;
  list-style: none;
  display: inline;
}

.blog .blog-details .meta-bottom .cats li {
  display: inline-block;
}

.blog .blog-details .meta-bottom .tags {
  padding: 0;
  font-size: 14px;
  list-style: none;
  display: inline;
}

.blog .blog-details .meta-bottom .tags li {
  display: inline-block;
}

.blog .blog-details .meta-bottom .tags li + li:before {
  color: var(--color-default);
  content: ",";
  padding-right: 6px;
}

.blog .blog-details .meta-bottom .share {
  font-size: 16px;
}

.blog .blog-details .meta-bottom .share i {
  padding-left: 5px;
}

.blog .post-author {
  margin-top: 30px;
  padding: 20px;
  box-shadow: 0 4px 16px #0000001a;
}

.blog .post-author img {
  max-width: 120px;
  margin-right: 20px;
}

.blog .post-author h4 {
  color: var(--color-secondary);
  margin-bottom: 0;
  padding: 0;
  font-size: 22px;
  font-weight: 600;
}

.blog .post-author .social-links {
  margin: 0 10px 10px 0;
}

.blog .post-author .social-links a {
  color: #52565e80;
  margin-right: 5px;
}

.blog .post-author p {
  color: #6c757dcc;
  margin-bottom: 0;
  font-style: italic;
}

.blog .sidebar {
  padding: 30px;
  box-shadow: 0 4px 16px #0000001a;
}

.blog .sidebar .sidebar-title {
  color: var(--color-secondary);
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: 700;
}

.blog .sidebar .sidebar-item + .sidebar-item {
  margin-top: 40px;
}

.blog .sidebar .search-form form {
  background: #fff;
  border: 1px solid #52565e4d;
  padding: 3px 10px;
  position: relative;
}

.blog .sidebar .search-form form input[type="text"] {
  width: calc(100% - 40px);
  border: 0;
  border-radius: 4px;
  padding: 4px;
}

.blog .sidebar .search-form form input[type="text"]:focus {
  outline: none;
}

.blog .sidebar .search-form form button {
  background: none;
  background: var(--color-primary);
  color: var(--color-secondary);
  border: 0;
  border-radius: 0 4px 4px 0;
  margin: -1px;
  padding: 0 15px;
  font-size: 16px;
  line-height: 0;
  transition: all .3s;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.blog .sidebar .search-form form button i {
  line-height: 0;
}

.blog .sidebar .search-form form button:hover {
  background: #feb900cc;
}

.blog .sidebar .categories ul {
  padding: 0;
  list-style: none;
}

.blog .sidebar .categories ul li + li {
  padding-top: 10px;
}

.blog .sidebar .categories ul a {
  color: var(--color-secondary);
  transition: all .3s;
}

.blog .sidebar .categories ul a:hover {
  color: var(--color-default);
}

.blog .sidebar .categories ul a span {
  color: #364d5966;
  padding-left: 5px;
  font-size: 14px;
}

.blog .sidebar .recent-posts .post-item {
  display: flex;
}

.blog .sidebar .recent-posts .post-item + .post-item {
  margin-top: 15px;
}

.blog .sidebar .recent-posts img {
  max-width: 80px;
  margin-right: 15px;
}

.blog .sidebar .recent-posts h4 {
  font-size: 15px;
  font-weight: bold;
}

.blog .sidebar .recent-posts h4 a {
  color: var(--color-secondary);
  transition: all .3s;
}

.blog .sidebar .recent-posts h4 a:hover {
  color: var(--color-primary);
}

.blog .sidebar .recent-posts time {
  color: #364d5966;
  font-size: 14px;
  font-style: italic;
  display: block;
}

.blog .sidebar .tags {
  margin-bottom: -10px;
}

.blog .sidebar .tags ul {
  padding: 0;
  list-style: none;
}

.blog .sidebar .tags ul li {
  display: inline-block;
}

.blog .sidebar .tags ul a {
  color: #838893;
  border: 1px solid #83889366;
  margin: 0 6px 8px 0;
  padding: 6px 14px;
  font-size: 14px;
  transition: all .3s;
  display: inline-block;
}

.blog .sidebar .tags ul a:hover {
  color: var(--color-secondary);
  border: 1px solid var(--color-primary);
  background: var(--color-primary);
}

.blog .sidebar .tags ul a span {
  color: #838893cc;
  padding-left: 5px;
  font-size: 14px;
}

.blog .comments {
  margin-top: 30px;
}

.blog .comments .comments-count {
  font-weight: bold;
}

.blog .comments .comment {
  margin-top: 30px;
  position: relative;
}

.blog .comments .comment .comment-img {
  margin-right: 14px;
}

.blog .comments .comment .comment-img img {
  width: 60px;
}

.blog .comments .comment h5 {
  margin-bottom: 2px;
  font-size: 16px;
}

.blog .comments .comment h5 a {
  color: var(--color-default);
  font-weight: bold;
  transition: all .3s;
}

.blog .comments .comment h5 a:hover {
  color: var(--color-primary);
}

.blog .comments .comment h5 .reply {
  color: var(--color-secondary);
  padding-left: 10px;
}

.blog .comments .comment h5 .reply i {
  font-size: 20px;
}

.blog .comments .comment time {
  color: #52565ecc;
  margin-bottom: 5px;
  font-size: 14px;
  display: block;
}

.blog .comments .comment.comment-reply {
  padding-left: 40px;
}

.blog .comments .reply-form {
  margin-top: 30px;
  padding: 30px;
  box-shadow: 0 4px 16px #0000001a;
}

.blog .comments .reply-form h4 {
  font-size: 22px;
  font-weight: bold;
}

.blog .comments .reply-form p {
  font-size: 14px;
}

.blog .comments .reply-form input {
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
}

.blog .comments .reply-form input:focus {
  box-shadow: none;
  border-color: #feb900cc;
}

.blog .comments .reply-form textarea {
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
}

.blog .comments .reply-form textarea:focus {
  box-shadow: none;
  border-color: #feb900cc;
}

.blog .comments .reply-form .form-group {
  margin-bottom: 25px;
}

.blog .comments .reply-form .btn-primary {
  background-color: var(--color-secondary);
  border: 0;
  border-radius: 4px;
  padding: 10px 20px;
}

.blog .comments .reply-form .btn-primary:hover {
  color: var(--color-secondary);
  background-color: var(--color-primary);
}

.footer {
  color: #fff;
  background: url("image59.41b46f88.jpeg") center / cover no-repeat;
  padding: 80px 0 60px;
  font-size: 14px;
  position: relative;
}

.footer:before {
  content: "";
  background: #000c;
  position: absolute;
  inset: 0;
}

.footer .footer-content .footer-info {
  margin-bottom: 30px;
}

.footer .footer-content .footer-info h3 {
  text-transform: uppercase;
  margin: 0 0 20px;
  padding: 2px 0;
  font-size: 28px;
  font-weight: 700;
  line-height: 1;
}

.footer .footer-content .footer-info h3 span {
  color: var(--color-primary);
}

.footer .footer-content .footer-info p {
  font-size: 14px;
  line-height: 24px;
  font-family: var(--font-primary);
  color: #fff;
  margin-bottom: 0;
}

.footer .footer-content .social-links a {
  color: #fff;
  width: 36px;
  height: 36px;
  background: #ffffff1a;
  border-radius: 4px;
  margin-right: 8px;
  font-size: 18px;
  line-height: 1;
  transition: all .3s;
  display: inline-block;
}

.footer .footer-content .social-links a:hover {
  background: var(--color-primary);
  text-decoration: none;
}

.footer .footer-content h4 {
  color: #fff;
  padding-bottom: 12px;
  font-size: 16px;
  font-weight: 600;
  position: relative;
}

.footer .footer-content .footer-links {
  margin-bottom: 30px;
}

.footer .footer-content .footer-links ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer .footer-content .footer-links ul li {
  padding: 8px 0;
}

.footer .footer-content .footer-links ul li:first-child {
  padding-top: 0;
}

.footer .footer-content .footer-links ul a {
  color: #fff9;
  line-height: 1;
  transition: all .3s;
  display: inline-block;
}

.footer .footer-content .footer-links ul a:hover {
  color: #fff;
}

.footer .footer-legal .copyright {
  border-top: 1px solid #ffffff26;
  padding-top: 30px;
}

.footer .footer-legal .credits {
  color: #fff;
  padding-top: 4px;
  font-size: 13px;
}

.footer .footer-legal .credits a {
  color: var(--color-primary);
}

/*# sourceMappingURL=index.ecc4263a.css.map */
